<!-- 店铺中心详情页面 -->
<template>
  <div class="shopDetail-container">
    <el-drawer
      class="shopDetail-drawer"
      title="店铺详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div
         v-loading="loading2" element-loading-text="数据较多，拼命加载中..."
        class="drawer-container"
      >
        <div class="drawer-left">
          <div class="left-top">
            <div class="detail-title">
              <div class="tit-info">
                <div class="shopLogo-container">
                  <img
                    v-if="shopInfo.shopLogo"
                    :src="shopInfo.shopLogo"
                    alt=""
                  />
                  <!-- <img @click="logoFun" v-if="shopInfo.shopLogo" :src="shopInfo.shopLogo" alt=""> -->
                  <i v-else class="el-icon-user common-iconImg-MR"></i>
                  <!-- <i @click="logoFun" v-else class="el-icon-user common-iconImg-MR"></i> -->
                  <!-- <input style="width: 0;display: none;" ref="uploadLogo" type="file" @input="chooseFile"> -->
                </div>
                <div class="shopName-custName">
                  <span>{{ shopInfo.shopName || "--" }}</span>
                  <span>{{ shopInfo.customerName || "--" }}</span>
                </div>
                <div
                  v-if="shopInfo.customerState == 3"
                  class="common-tableStatus-red"
                >
                  服务终止
                </div>
                <div
                  v-if="shopInfo.customerState == 1"
                  class="common-tableStatus-green"
                >
                  正在服务
                </div>
                <div
                  v-if="shopInfo.customerState == 2"
                  class="common-tableStatus-blue"
                >
                  待服务
                </div>
                <div
                  v-if="shopInfo.cmsSubaccount == 0"
                  class="common-tableStatus-red"
                  style="margin-left: 16px"
                >
                  未分配客服
                </div>
                <div
                  v-if="shopInfo.cmsSubaccount == 1"
                  class="common-tableStatus-blue"
                  style="margin-left: 16px"
                >
                  已分配客服
                </div>
              </div>
            </div>
            <div class="detail-content">
              <div class="hoverEdit">
                店铺名称:
                <span class="DisableEdit">{{ shopInfo.shopName || "--" }}</span>
              </div>
              <div class="hoverEdit">
                所属平台:
                <span class="DisableEdit">{{
                  shopInfo.platformName || "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                所属类目:
                <span class="DisableEdit">{{
                  shopInfo.categoryName || "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                开始时间:
                <span class="DisableEdit">{{
                  DateTransform(shopInfo.startTime) || "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                结束时间:
                <span class="DisableEdit">{{
                  DateTransform(shopInfo.endTime) || "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                联系人:
                <span class="DisableEdit">{{
                  shopInfo.contactName || "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                联系方式:
                <span class="DisableEdit">{{
                  shopInfo.contactPhone || "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                运营负责:
                <span class="DisableEdit">{{
                  shopInfo.busineseUser || "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                预警时间:
                <span class="DisableEdit">{{
                  shopInfo.beforeDay ? "提前" + shopInfo.beforeDay + "天" : "--"
                }}</span>
              </div>

              <div class="hoverEdit">
                当日发送时间:
                <span class="DisableEdit">{{
                  shopInfo.warnTime ? shopInfo.warnTime : "--"
                }}</span>
              </div>
            </div>
          </div>
          <div class="upload-container">
            <div class="upload-top">
              <div class="upload-title">
                附件
              </div>
              <div class="tip">
                上传图片及文件，大小不超过50MB
                <div class="uploadBtn-container">
                  上传文档<input
                    @input="
                      (e) => {
                        uploadFile(e);
                      }
                    "
                    class="uploadBtn-input"
                    type="file"
                  />
                </div>
              </div>
            </div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div
                v-for="(item, index) in fileList"
                :key="index"
                class="fileItem"
              >
                <div class="fileItem-name">
                  <common-picture
                    :fileUrl="item.url"
                    :fileType="
                      Config.fileType.imgType.includes(
                        item.url.split('.')[item.url.split('.').length - 1]
                      )
                        ? 'imgText'
                        : 'file'
                    "
                    :fileName="item.name"
                  ></common-picture>
                </div>
                <div class="fileItem-btn-Container">
                  <div
                    class="fileItemBtn"
                    @click="
                      () => {
                        download(item.url);
                      }
                    "
                  >
                    下载
                  </div>
                  <el-popconfirm
                    placement="top"
                    title="确定删除吗？"
                    @confirm="deleteFile(item)"
                  >
                    <el-button type="text" size="small" slot="reference"
                      >删除</el-button
                    >
                  </el-popconfirm>
                </div>
              </div>
            </div>
            <div
              v-if="!fileList || !fileList.length"
              class="fileList-container"
            >
              <div class="fileItem">
                暂无合同
              </div>
            </div>
          </div>

          <div>
            <div class="protit">问题记录</div>
            <div class="common-screen-container">
              <div class="common-input-container">
                <common-lately-date
                  @commonGetDate="commonGetDate"
                  :commonRadio="commonRadio"
                  :startDate="problemParams.startDate"
                  :endDate="problemParams.endDate"
                  ref="commonReset"
                ></common-lately-date>
              </div>
              <div class="common-input-container">
                <el-button
                  class="common-screen-btn"
                  plain
                  @click="() => resetBtn()"
                  >重 置</el-button
                >
              </div>
              <div class="common-input-container">
                <el-radio v-model="radio" label="0" @change="changeRadio"
                  >仅显示未完成状态</el-radio
                >
              </div>
            </div>
            <el-table class="common-table" :data="problemList" :fit="true">
              <el-table-column prop="createTime" label="日期">
              </el-table-column>
              <el-table-column prop="createName" label="客服">
              </el-table-column>
              <el-table-column label="服务时段">
                <template slot-scope="scope">
                  <span>{{ scope.row.startTime }}</span>
                  <span>{{ scope.row.endTime }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="problemRemark" label="问题记录">
              </el-table-column>
              <el-table-column label="当前状态">
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.isDeal == '1'"
                    class="common-tableStatus-green-bgc"
                  >
                    已完成
                  </div>
                  <div
                    v-if="scope.row.isDeal == '0'"
                    class="common-tableStatus-orange-bgc"
                  >
                    未完成
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    @click="() => followUp(scope.row)"
                    >跟进</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @size-change="(e) => pageFunOrder(e, 'pageSize')"
              @current-change="(e) => pageFunOrder(e)"
              style="text-align: center"
              :page-sizes="paginationOrder.pageSizes"
              :page-size="problemParams.pageSize"
              :current-page="problemParams.pageNum"
              layout="total, prev, pager, next,sizes, jumper"
              :total="paginationOrder.total"
            ></el-pagination>
            <br />
          </div>
        </div>
        <div class="drawer-right">
          <el-tabs
            style="height: 100%;display: flex;flex-direction: column;"
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane label="跟进记录" name="about">
              <common-work-log
                :followRecordList="followRecordList"
              ></common-work-log>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>

    <!-- 跟进 -->
    <el-dialog
      v-dialogDrag
      title="跟进描述"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      :before-close="handleClose2"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="跟进状态:" prop="followType">
          <el-select v-model="form.followType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="说明:" prop="problemRemark">
          <el-input
            class="dialog-input"
            type="textarea"
            v-model="form.problemRemark"
            maxLength="500"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="orderFile-container">
            <div class="orderFileBtn">
              上传附件
              <input
                class="orderFile-input"
                type="file"
                @input="
                  (e) => {
                    chooseFileFlow(e);
                  }
                "
              />
            </div>
            上传文件及图片，大小不超过50MB
          </div>
          <div v-if="filesList && filesList.length" class="fileList-container">
            <div
              v-for="(item, index) in filesList"
              :key="index"
              class="fileItem"
              style="display: flex;justify-content: space-between;"
            >
              <div class="fileItem-name">
                <common-picture
                  :fileUrl="item.fileUrl"
                  :fileType="item.fileType"
                  :fileName="item.fileName"
                ></common-picture>
              </div>
              <div class="fileItem-btn-Container">
                
                <el-popconfirm
                  placement="top"
                  title="确定删除吗？"
                  @confirm="deleteFileWork(item.fileId)"
                >
                  <el-button type="text" size="small" slot="reference"
                    >删除</el-button
                  >
                </el-popconfirm>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="
            () => {
              sendFollowUp();
            }"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  selectShopProblemDetail,
  insertShopProblem
} from "../../../service/service.js";
import { upload } from "../../../service/upload.js";
import {
  workLog,
  selectCategory,
  selectPlatform
} from "../../../service/common.js";
import {
  tableColumn,
  DateTransform,
  Config,
  getLatelyDay
} from "../../../utils/index.js";
import CommonWorkLog from "../../../components/common/commonWorkLog.vue";
import CommonPicture from "../../../components/common/commonPicture.vue";
import {
  uploadShopFile,
  deleteShopFile,
  shopDetail
} from "../../../service/customer.js";
import CommonLatelyDate from "../../../components/common/commonLatelyDate.vue";

export default {
  props: ["drawer"],
  components: { CommonWorkLog, CommonPicture, CommonLatelyDate },
  data() {
    return {
      Config,
      tableColumn,
      DateTransform,
      tableRow: {}, // 表格数据
      shopInfo: {},
      loading2: false,
      fileList: [],
      orderTable: [],
      paginationOrder: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      followRecordList: [], // 工作日志
      filesList: [], // 跟进文件
      isShowInput: "",
      activeName: "about", // tabs
      activeTab: this.$route.query.active ? this.$route.query.active : "1", // tabs
      recordContent: "", // 跟进记录内容
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ""
      },
      commonRadio: 1,
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company, // 所属公司下拉
      serveData: {}, // 店铺服务数据
      setDialogVisible: false,
      flag: false,
      setTableData: {},
      deData: {},
      problemParams: {
        startDate: DateTransform(getLatelyDay(7)[0]),
        endDate: DateTransform(getLatelyDay(7)[1]),
        pageNum: 1,
        pageSize: 10
      },
      problemList: [],
      radio: "1",
      dialogVisible: false,
      options: [
        { value: 1, label: "成功" },
        { value: 2, label: "跟进" },
        { value: 3, label: "新建" }
      ],
      form: {
        followType: "",
        problemRemark: "",
        fileIds: ""
      },
      problemId: ""
    };
  },
  created() {
    // this.getPlatList()
    // if (this.rolesCompany) {
    //   this.serveParams.companyId = this.rolesCompany[0].id;
    // }
    this.selectPlatform();
    this.selectCategory();
  },
  methods: {
    selectPage(type) {
      // 下拉分页
      if (type == "up") {
        this.categoryParams.pageNum--;
      } else {
        this.categoryParams.pageNum++;
      }
      this.selectCategory();
    },
    remoteCategory(val) {
      // 所属类目下拉-筛选
      this.categoryParams.pageNum = 1;
      this.categoryParams.name = val;
      this.selectCategory();
    },
    async selectCategory() {
      // 类目下拉
      let categoryParams = { ...this.categoryParams };
      categoryParams.pageNum = -1;
      let { data } = await selectCategory(categoryParams);
      // this.categoryList = data.list
      this.categoryList = data;
    },
    async selectPlatform() {
      // 平台下拉
      let { data } = await selectPlatform({ pageNum: -1 });
      this.platList = data;
    },
    openNewWin(url) {
      window.open(url);
    },
    logoFun() {
      // 更换头像
      if (this.$refs.uploadLogo) {
        this.$refs.uploadLogo.click();
      }
    },
    async chooseFile(e) {
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      if (!Config.fileType.imgType.includes(format)) {
        e.target.value = "";
        return this.$message.error(
          `只能上传 ${Config.fileMessage.imgType} 格式的文件`
        );
      }
      let { data } = await upload({ file: e.target.files[0] });
      this.editRuleForm["shopLogo"] = data.filename;
      this.editParams.filedName = "shopLogo";
      this.editShopDetail("shopLogo");
    },
    // async getPlatList() { // 所属平台/类目
    //   let { data } = await getPlatList({ pageNum: -1 });
    //   this.platList = data;
    // },
    async shopDetail(id) {
      // 店铺详情
      let { data } = await shopDetail({}, id);
      if (data.platformId) {
        if (data.categoryId) {
          data.platId = [Number(data.platformId), Number(data.categoryId)];
        } else {
          data.platId = [Number(data.platformId)];
        }
      } else {
        data.platId = [];
      }
      if (data.busineseUserIds) {
        data.busineseUserIds = data.busineseUserIds.split(",");
      } else {
        data.busineseUserIds = [];
      }
      this.shopInfo = data;
      this.form.name = data.shopName;
      if (data.fileUrl && data.fileName) {
        this.fileList = [
          { url: data.fileUrl, name: data.fileName, id: data.fileId }
        ];
      } else {
        this.fileList = [];
      }
    },
    getDataInfo(row) {
      // 父组件传默认值
      this.tableRow = row;
      this.getShopProblemDetail(row.shopId);
      this.workLog(row.shopId);
    },
    async deleteFile(item) {
      // 删除文档
      let params = {
        shopId: this.tableRow.shopId,
        filedId: item.id,
        filedUrl: item.url,
        fileName: item.name
      };
      this.loading2 = true;
      let { code } = await deleteShopFile(params);
      this.loading2 = false;
      if (code == 200) {
        this.$message.success("删除成功");
      }
      this.shopDetail(this.tableRow.shopId);
      this.workLog(this.tableRow.shopId);
    },
    async uploadFile(e) {
      // 上传附件文档
      let fileList = [...this.fileList];
      if (fileList && fileList.length) {
        e.target.value = "";
        return this.$message.error("文档不能重复上传，请删除后重新上传");
      }
      let file = e.target.files[0];
      let fileName = file.name;
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(
          `只能上传 ${Config.fileMessage.PWEIType} 格式的文件`
        );
      }
      this.loading2 = true;
      let resData = (await upload({ file: file })).data;
      let { code } = await uploadShopFile({
        shopId: this.tableRow.shopId,
        filedId: resData.id,
        fileName: fileName,
        filedUrl: resData.filename
      });
      if (code == 200) {
        this.$message.success("上传成功");
      }
      e.target.value = "";
      this.shopDetail(this.tableRow.shopId);
      this.workLog(this.tableRow.shopId);
      this.loading2 = false;
    },
    download(url) {
      // 下载
      window.location.href = url;
    },
    deleteFileWork(id) {
      let filesList = [...this.filesList];
      this.filesList = filesList.filter((item) => {
        return item.fileId != id;
      });
    },
    async workLog(id) {
      // 工作日志
      let resData = (await workLog({ id: id, type: 5 })).data;
      if (resData && resData.length) {
        resData.map((item) => {
          if (item.value) {
            item.value = JSON.parse(item.value);
          } else {
            item.value = [];
          }
        });
      }
      this.followRecordList = resData;
    },
    async chooseFileFlow(e) {
      // 跟进文件上传
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error("文件大小不能超过50M");
      }
      if (!Config.fileType.imgType.includes(format)) {
        e.target.value = "";
        return this.$message.error(
          `只能上传 ${Config.fileMessage.imgType} 格式的文件`
        );
      }
      this.loading2 = true;
      let resData = (await upload({ file: file })).data;
      this.loading2 = false;
      let fileItem = {
        fileType: Config.fileType.imgType.includes(
          file.name.split(".")[file.name.split(".").length - 1]
        )
          ? "imgText"
          : "file",
        fileName: file.name,
        fileId: resData.id,
        fileUrl: resData.filename
      };
      // if ( format == "bmp" || format == "jpg" || format == "png" || format == "gif" || format == "jpeg" ) {
      //   fileItem.fileType = "img";
      // }
      this.filesList.push(fileItem);
    },
    handleClose(done) {
      this.$emit("handleClose", done);
      this.activeTab = "1";
      this.commonRadio = 2;
    },
    handleClose2() {
      this.fileList = []
      this.$refs.form.resetFields();
    },
    handleClick(tab) {
      if (tab.name == 3) {
        this.getSetList().then(() => {
          this.getDefaultData();
        });
      } else if (tab.name == 4) {
        this.getChildList();
      }
    },
    loadFun(value) {
      this.$emit("loadFun", value);
    },
    commonGetDate(startTime, endTime, commonRadio) {
      this.problemParams.startDate = startTime;
      this.problemParams.endDate = endTime;
      this.commonRadio = commonRadio;
      this.getShopProblemDetail(this.tableRow.shopId);
    },
    resetBtn() {
      this.commonRadio = null;
      this.problemParams = {
        startDate: DateTransform(getLatelyDay(7)[0]),
        endDate: DateTransform(getLatelyDay(7)[1]),
        pageNum: 1,
        pageSize: 10
      };
      this.$refs.commonReset.resetFun(
        1,
        DateTransform(getLatelyDay(7)[0]),
        DateTransform(getLatelyDay(7)[1])
      );
      this.getShopProblemDetail(this.tableRow.shopId);
    },
    setHandleClose() {
      this.form.resource = 1;
      this.form.firstTime = this.flag
        ? this.setTableData.firstTime
        : this.deData.firstTime;
      this.form.avgTime = this.flag
        ? this.setTableData.avgTime
        : this.deData.avgTime;
      this.form.questionAnswerRatio = this.flag
        ? this.setTableData.questionAnswerRatio
        : this.deData.questionAnswerRatio;
      this.form.thereTimeRatio = this.flag
        ? this.setTableData.thereTimeRatio
        : this.deData.thereTimeRatio;
      this.form.fiveTimeRatio = this.flag
        ? this.setTableData.fiveTimeRatio
        : this.deData.fiveTimeRatio;
      this.setDialogVisible = false;
    },
    openUrl() {
      window.open("/data/customer-service-data");
    },
    async getShopProblemDetail(id) {
      this.loading2 = true
      let params = { ...this.problemParams };
      params.shopId = id;
      const { data } = await selectShopProblemDetail(params);
      this.shopInfo = data;
      if (data.fileUrl && data.fileName) {
        this.fileList = [
          { url: data.fileUrl, name: data.fileName, id: data.fileId }
        ];
      } else {
        this.fileList = [];
      }
      this.problemList = data.problemList.list;
      this.paginationOrder.total = data.problemList.total;
      this.loading2 = false
    },
    pageFunOrder(e, type) {
      // 订单分页
      if (type == "pageSize") {
        this.problemParams.pageSize = e;
      } else {
        this.problemParams.pageNum = e;
      }
      this.getShopProblemDetail(this.tableRow.shopId);
    },
    deleteFlie(i) {
      let fileList = { ...this.fileList };
      fileList.name = fileList.name.filter((item, index) => {
        return i != index;
      });
      fileList.id = fileList.id.filter((item, index) => {
        return i != index;
      });
      fileList.url = fileList.url.filter((item, index) => {
        return i != index;
      });
      this.fileList = fileList;
    },
    changeRadio() {
      if (this.radio == "0") {
        this.problemParams.isDeal = 0;
      }
      this.getShopProblemDetail(this.tableRow.shopId);
    },
    followUp(row) {
      this.dialogVisible = true;
      this.problemId = row.problemId
      // this.form.shopId = row.shopId;

    },
    async sendFollowUp() {
      let params = { ...this.form };
      let fileIds = []
      if(this.filesList&&this.filesList.length){
        this.filesList.forEach((item)=> {
          fileIds.push(item.fileId)
        })
      }
      params.fileIds = fileIds.join(',')
      params.shopId = this.tableRow.shopId;
      params.problemId = this.problemId
      const res = await insertShopProblem(params);
      if(res.code == 200 ) {
        this.$message.success("跟进成功")
      }
      this.fileList = []
      this.$refs.form.resetFields();
      this.dialogVisible = false;
      this.getShopProblemDetail(this.tableRow.shopId);
    }
  }
};
</script>
<style lang="less" scoped>
.shopDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .drawer-container {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .left-top {
        // display: flex;
        // justify-content: space-between;
        .demo-ruleForm {
          width: 920px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          border: 1px solid #ebeef5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #ebeef5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890ff;
            color: #1890ff;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .detail-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 16px 12px;
      .tit-info {
        display: flex;
        align-items: center;
        .shopLogo-container {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
          }
          .common-iconImg-MR {
            width: 64px;
            height: 64px;
            font-size: 50px;
            line-height: 64px;
          }
        }
        .shopName-custName {
          display: inline-block;
          margin: 0 16px;
          span {
            display: block;
            &:nth-of-type(1) {
              color: #333;
              font-size: 18px;
            }
            &:nth-of-type(2) {
              color: #999;
              font-size: 14px;
            }
          }
        }
        .orderState {
          padding: 2px 8px;
          border-radius: 4px;
          border: 1px solid #f98787;
          color: #f98787;
          margin-left: 16px;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .note-input-container {
        display: inline-block;
        width: 480px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input-container {
        display: inline-block;
        width: 280px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 280px;
        white-space: nowrap;
      }
      .edit-input {
        width: 180px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .noteHoverEdit {
        display: inline-block;
        // width: 100%;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        display: flex;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99%
            center #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;

        &:last-child {
          width: 360px;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 200px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-bottom: 18px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
  .common-screen-container {
    margin: 0;
    margin-bottom: 8px;
    margin-top: 24px;
    .set {
      height: 32px;
      line-height: 8px;
      margin-left: 28px;
    }
  }
  .tableshow {
    text-align: right;
    margin-bottom: 32px;
    .square {
      display: inline-block;
      width: 10px;
      height: 10px;
      &:not(:first-child) {
        margin-left: 40px;
      }
    }
    .no {
      background-color: #757775;
    }
    .y {
      background-color: #56c51e;
    }
    .n {
      background-color: #f5232d;
    }
  }
  .systemServiceData-content {
    margin: 48px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .systemServiceData-table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      td,
      th {
        text-align: center;
        border: 1px solid #c9c9c9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
      th {
        background: #f5f5f5;
        height: 43px;
        line-height: 43px;
        font-weight: 600;
      }
      td {
        height: 58px;
        line-height: 58px;
      }
    }
  }
}
.ulline {
  padding-left: 32px;
  border-left: 3px solid #1890ff;
  .lione {
    display: inline-block;
    width: 150px;
    color: #101010;
  }
  .litwo {
    color: #989998;
    font-size: 14px;
  }
  .licolor {
    color: #101010;
  }
  .ulipt {
    width: 92px;
  }
}
.fontRed {
  color: #f24433;
}
.fontGreen {
  color: #56c51e;
}
.zi {
  color: #1890ff;
  border: 1px solid #1890ff;
}
.protit {
  margin-top: 46px;
  font-size: 18px;
  color: #101010;
}
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
    .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
  }
}
.orderFile-container {
  .orderFileBtn {
    width: 100px;
    height: 32px;
    line-height: 32px;
    border: 1px solid rgba(220, 223, 230, 100);
    color: #666;
    background: #fff;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-radius: 4px;
    display: inline-block;
    .orderFile-input {
      width: 100px;
      height: 32px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
  }
}
</style>
<style lang="less">
.fileList-container {
  .fileList-item {
    display: flex;
    justify-content: space-between;
    width: 320px;
    .item-name {
      font-size: 12px;
      color: #333;
    }
    .deleteBtn {
      color: #1890ff;
      cursor: pointer;
    }
    .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
  }
}
</style>
